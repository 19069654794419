const ourFriends = [
    {
        name:"Unicef Srbija",
        link:"https://www.unicef.org/serbia/",
        img:"unicef_logo.png"
    },
    {
        name:"FPN",
        link:"https://www.fpn.bg.ac.rs/",
        dsc:"Fakultet političkih nauka",
        img:"fpn_logo.png"
    },{
        name:"ZUOV",
        dsc:"Zavod za unapređenje obrazovanja i vaspitanja",
        link:"https://zuov.gov.rs/",
        img:"zuov_logo.png"
    },{
        name:"WWF",
        link:"https://www.wwfadria.org/sr/",
        img:"wwf_logo.png"
    },{
        name:"Građanske inicijative",
        link:"https://www.gradjanske.org/",
        img:"gi_logo.png"
    },{
        name:"DFF",
        link:"https://www.facebook.com/Dijalogzabuducnost",
        img:"dff_logo.png"
    },{
        name:"Filozofski fakultet",
        link:"https://www.f.bg.ac.rs/",
        img:"ff_logo.png"
    },
];


export default ourFriends;
const contacts = [
    {
        id:0, 
        name:"Nacionalna asocijacija: NAGVIS", 
        email:["asocijacijagv@gmail.com", "nagvisinfogv@gmail.com", "nagvisobuke@gmail.com"], 
        phone:"+381 64 117 67 85", 
        fb:"https://www.facebook.com/nagvis.rs", 
        ig:"https://www.instagram.com/nagvis.rs",
        person:"Melita Ranđelović , predsednica udruženja",
        img:"",
        txt:""
    },
    {
        id:1, 
        name:"Beograd NGV",
        email:["nagvis.beograd@gmail.com"], 
        phone:"+381 63 7775 170", 
        fb:"https://facebook.com/Nagvis-Beograd-103649105117745", 
        ig:"https://instagram.com/nagvisbgd",
        person:"Ljiljana Panjković, koordinatorka",
        img:"bgd.png",
        txt:'Okuplja nastavnike i nastavnice građanskog vaspitanja (GV) na teritoriji Beograda.<br><br>Glavni cilj nam je da promovišemo kvalitetno izvođenje GV  kao programa nastave i učenja, povežemo se i profesionalno osnažimo, razvijamo nastavničke kompetencije kroz različite oblike usavršavanja, razmenu korisnih informacija, iskustva, ideja i materijala.<br><br>Otvoreni smo za saradnju sa lokalnim institucijama  i relevantnim organizacijama kako bismo zajedno doprinosili demokratizaciji škola i našeg Beograda. <br><br> Naš tim:',
        team:[
            {name:'Ljiljana Panjković', dsc:'koordinatorka centra, sociološkinja i nastavnica građanskog vaspitanja i izbornih programa u Srednjoj školi za ekonomiju, pravo i administraciju - Stari grad', img:"image5.jpg"},
            {name:'Slavica Mladenović Ivanović', dsc:'učiteljica OŠ „Nikola Tesla“ - Grocka', img:"image8.jpg"},
            {name:'Ljiljana Petrović', dsc:'nastavnica građanskog vaspitanja i izbornih programa u Gimnaziji „Patrijarh Pavle“ - Rakovica ', img:"image2.jpg" },
            {name:'Ivana Jevđenović', dsc:'nastavnica građanskog vaspitanja i francuskog jezika u OŠ „14.oktobar“ - Barič', img:"image1.jpg" },
            {name:'Snežana Rakić', dsc:'pedagoškinja  OŠ „Nikola Tesla“ - Grocka', img:'image7.jpg'},
            {name:'Milica Rajević', dsc:'nastavnica građanskog vaspitanja zaposlena u Sportskoj gimanziji -  Stari grad', img:"woman.jpg" },
            {name:'Jelena Krstić', dsc:'pedagoškinja i vaspitačica', img:"image3.jpg"}
          ]
    },
    {
        id:2, 
        name:"3 K", 
        email:["3kcentralnasrbija@gmail.com"], 
        phone:"+381 63 331 793", 
        fb:"https://www.facebook.com/NaGVisTIM3K/", 
        ig:"https://instagram.com/3kcentralnasrbija",
        person:"Jelica Milutinović",
        img:"3k.png",
        txt:'Tim 3K čine nastavnici/ce  građanskog vaspitanja (GV), koji zajedno sa učenicima i učenicama  u svojim školama, lokalnoj sredini i regionu stvaraju prostor za sticanje praktičnih veština koje će nas učiniti aktivnim u društvu, svesnim građanima,  kritički orjentisanim  i odgovornim.<br><br>Iz gradova Centralne Srbije dolaze nam nastavnici/ce spremni da iskorakom iz škole, u svojoj lokalnoj  sredini budu ključni stub i faktor saradnje sa svim organizacijama, institucijama i ustanovama koje se bave  pitanjima relevantnim za participaciju učenika/ca. Tim Centalna Sbija na početku je  imao nas troje:  Ivana Karadarevića iz Kragujevca, Jelicu Milutinović iz Kraljeva i Sanju Marković iz Kosovske Mitrovice i  ime dobio simbolično po prvom slovu naših gradova. Sve nas je više jer spajamo ljude i gradove: Čačak, Arandjelovac, Kruševac, Vrnjačka Banja... <br><br>Oni su ti koji, zajedno sa učenicima, u javnost izlaze akcijom, ukazuju na dragocenu solidarnost,  prava i dužnosti  građana, sajber - bezbednost i digitalnu pismenost i istovremeno iskazuju zaštitnički odnos prema prirodi,  kulturnom nasleđu i održivom razvoju. <br><br>Otvoreni smo da se mreža širi među eksperte, medije , roditelje i donosioce odluka  koji će svoja ekonomska, pravna, socijalna, građanska i ekološka iskustva, podeliti sa nama i učenicima/cama.<br><br>Samo  zajedno, učenicima/cama i roditeljima pružamo korisne informacije i dajemo mogućnost da se razmatraju  teme koje su od opšteg interesa za: pojedinca,  školu, lokalnu zajednicu i društvo u celini.        <br><br>Samo saradnjom ,  primenom  i vidljivošću  usvojenih  informacija i stečenih  veština, stvaramo tle za:<br><br>“ Ono što dete može da uradi danas u saradnji sa drugima – sutra će moći da uradi samo “ - Lav Vigotski ',
        team_img:'image26.png'
    },
    {
        id:3, 
        name:"Vojvođanski naGVis", 
        email:["vojvodjanskinagvis@gmail.com"], 
        phone:"", 
        fb:"https://www.facebook.com/vojvodjanski.nagvis", 
        ig:"https://www.instagram.com/vojvodjanskinagvis/",
        person:"",
        img:"vojvodina.png",    
        txt:"Okupili smo se oko ideje da kroz umrežavanje nastavnika/ca građanskog vaspitanja sa teritorije Vojvodine razmenjujemo dobru praksu i na taj način podignemo kvalitet nastave građanskog vaspitanja, učinimo vidljivijim značaj građanskog vaspitanja u zajednici i podignemo kulturu građanskog društva. <br><br>Naš tim čine nastavnici/ce iz Novog Sada, Sremske Mitrovice, Kikinde, Vrbasa... a članovi su nam i iz drugih mesta jer smo otvoreni za saradnju i u zajedničkim aktivnostima ima mesta za svakog.",

        team:[
            {name:'Filip Nikolić', dsc1:'nastavnik građanskog vaspitanja i filozofije, Mitrovačka gimnazija Sremska Mitrovica', img:'image8.jpg'},
            {name:'Marina Tintor Popović', dsc1:'nastavnik građanskog vaspitanja i italijanskog jezika OŠ “Triva Vitasović Lebarnik” Laćarak,  nastavnik izbornog predmeta Jezik, mediji i kultura Mitrovačka gimnazija Sremska Mitrovica', img:'image11.jpg'},
            {name:'Miljana Kitanović', dsc1:'stručni saradnik,psiholog, Gimnazija „Dušan Vasiljev“ Kikinda', img:'image12.jpg' },
            {name:'Korana Milekić', dsc1:'nastavnik građanskog vaspitanja i stručni saradnik, pedagog Tehnička škola „Mileva Marić Ajnštajn“ Novi  Sad', img:'image31.jpg' },
            {name:'Ljiljana Nikolić', dsc1:'nastavnik građanskog vaspitanja i geografije Gimnazija „Isidora Sekulić“ Novi Sad2', img:'image3.jpg'},
            {name:'Svetlana Gurjanov Tašin', dsc1:'nastavnik građanskog vaspitanja i stručni saradnik, psiholog Medicinska škola „7.april“ Novi Sad  (koordinator)', img:'image10.jpg'}
          ]
    },
    {
        id:4, 
        name:"FENIKS - GV", 
        email:["feniksgv50@gmail.com"], 
        phone:"+381 64 904 13 17", 
        fb:"https://www.facebook.com/feniksnagvis/", 
        ig:"https://www.instagram.com/feniks_nagvis/",
        person:"Biljana Komarica ",
        img:"feniks.png",
        txt:'Svrha tima FENIKS je okupljanje i umrežavanje zainteresovanih nastavnika/ca da se uključe u rad opštinskih timova – čija je interesna sfera obrazovanje za demokratiju i građansko društvo. Naša ciljna grupa su: nastavnici/ce GV-a, koordinatori/ke učeničkog parlamenta, stručni saradnici/ce, profesori/ce filozofije, sociologije...<br><br>FENIKS okuplja članove i saradnike iz Nove Varoši, Prijepolja, Priboja, Bajine Bašte... koji realizuju program GV u osnovnim i srednjim školama.  Želimo da sa svojim učenicima i učenicama rastemo kroz rad na kreiranju  KABINETA ZA AKTIVIZAM „Po – kreni!“ i doprinesemo kvalitetnijoj realizaciji Građanskog vaspitanja i sadržajnijem radu Učeničkog parlamenta. <br><br>Članovi našeg tima su:'
        ,team:[
            { name:"Neca Jović ", dsc:"koordinatorka i predagoškinja", img:"necajovic.jpeg"},
            { name:"Bilja Komarica", dsc:"nastavnica građanskog vaspitanja i srpskog jezika i književnosti", img:"biljakomarica.jpeg"},
            { name:"Dragan Kuveljić", dsc:"učitelj", img:"dragankuveljic.jpeg"},
            { name:"Željko Živković", dsc:"nastavnik građanskog vaspitanja i srpskog jezika i književnosti", img:"zeljkozivkovic.jpeg"}
          ]
    },
    {
        id:5, 
        name:"ZA-LE-T", 
        email:["zaletgradjanskovaspitanje@gmail.com"], 
        phone:"", 
        fb:"", 
        ig:"",
        txt:"Tim su nastavnice koje realizuju programe građanskog vaspitanja (GV) u Zaječaru i Leskovcu i nastoje da okupe kolege i koleginice kojima je stalo do kvalitetne nastave GV kojom se doprinosi demokratizaciji škole. Osim unapređivanja metodike GV i same nastave, kroz saradnju i profesionalno osnaživanje kolega i koleginica koji rade u školama na jugu Srbije, podstičemo ih da kod svojih učenika/ca doprinesu razvijanju kritičkog promišljanja društvenih fenomena, solidarnosti, aktivizma.<br><br>Otvoreni smo za razmenu primera dobre prakse, podršku kolegama koji žele da svojim časovima GV daju dodatni smisao, vedrinu i kreiraju odgovorne učeničke akcije u lokalnoj sredini.<br><br>Naš tim su",
        person:"Aleksandra Stefanović (Leskovac) 069 237 53 00 | Slađa Stojanović (Zaječar) 065 231 42 18",
        img:"",
        team:[
            { name:"Aleksandra Stefanović", dsc:"koordinatorka, nastavnica građanskog vaspitanja i engleskog jezika",  img:"image17.jpg"},
            { name:"Tatjana Ilić", dsc:"nastavnica građanskog vaspitanja i srpskog jezika i književnosti", img:"image1.jpg"},
            { name:"jelena dimović", dsc:"nastavnica građanskog vaspitanja i srpskog jezika i književnosti", img:"jelena dimović.jpeg"},
            { name:"Tatjana Gavrilović", dsc:"nastavnica građanskog vaspitanja i francuskog jezika, bibliotekarka", img:"image14.jpg"},
            { name:"Aleksandra Arsić", dsc:"nastavnica građanskog vaspitanja i francuskog jezika", img:"image25.jpg"},
            { name:"stojanovic sladjana", dsc:" nastavnica građanskog vaspitanja i filozofije", img:"stojanovic sladjana.jpg"},
          ]
    },
];


export default contacts;
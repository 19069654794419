const pages = {
    home:{
        tabName:"Naslovna",
        title:"Aktuelnosti"
    },

    about:{
        tabName:"O nama",
        title:"O nama",
        txt1:'<b>Nacionalna asocijacija nastavnika/ca Građanskog vaspitanja i saradnika/ca (NAGVIS)</b> je udruženje koje su osnovali praktičari-nastavnici Građanskog vaspitanja sa ciljem okupljanja i umrežavanja nastavnika/ca Građanskog vaspitanja, prosvetnih radnika, stručnjaka i drugih zainteresovanih građana za aktivnosti unapređenja položaja programa, korisnika i nosilaca obrazovanja i vaspitanja za demokratiju i građansko društvo, prvenstveno kroz školski sistem.<br><br>Udruženje je osnovano 2020. godine uz podršku UNICEF-a, u sklopu regionalnog programa Dijalog za budućnost. <br><br>Na nacionalnom nivou udruženje  vode: Tatjana Matijaš, Ljiljana Jović, Melita Ranđelović (redosled na slici).',
        txt3:'Negujući od samog starta partnerstvo sa mladima, Asocijacija nastoji da uključi i  konsultuje mlade, koji unose svoj mladalački i pokretački duh. Jedna od njih je i Ana Stojmenović, studentkinja treće godine novinarstva i komunikologije na Fakultetu političkih nauka u Beogradu, angažovana i posvećena koordinatorka za saradnju sa medijima.',
        li1:{
            title:'Aktivnosti NAGVIS usmerene su u tri pravca:',
            itms:[
                'Jačanje kompetencija nastavnika/ca Građanskog vaspitanja za realizaciju programa. (Trenerski tim NAGVIS-a osmišljava i realzuje obuke za nastavnike/ce GV  u sva tri ciklusa obrazovanja)', 
                'Jačanje pozicije Građanskog vaspitanja na lokalnom i nacionalnom nivou. ', 
                'Stvaranje mreže praktičara, stručnjaka i svih drugih građana zainteresovanih za unapređenja programa građansko vaspitanje']},
        txt2:'U okviru NAGVIS-a formirano je nekoliko lokalnih centara kojima je pokriven prostor cele Srbije. Lokalni centri imaju svoje koordinacione timove, deluju u skladu sa ciljevima asocijacije i u svoje aktivnosti unose specifičnosti koje odgovaraju potrebama centra.',
        title2:"NAŠE VREDNOSTI",
        li2:[
            '<b>OTVORENOST</b> – ka promenama, novim idejama i prihvatanju različitosti.'
            ,
            '<b>RAZLIČITOST</b> – uvažavanje, poštovanje i uključivanje različitosti.'
            ,
            '<b>ODGOVORNOST</b> – prema javnom interesu.'
            ,
            '<b>POSVEĆENOST</b> – okrenutost ka cilju/ideji.'
            ,
            '<b>PARTNERSTVO</b> – razvoj i podrška saradnji.'
            ,
            '<b>PROAKTIVNOST</b> – pokretanje akcija, inicijativa.'
            ,
            '<b>TRANSPARENTNOST I DIREKTNOST</b> – u komunikaciji i zastupanju ideje.'
            ,
            '<b>SOLIDARNOST</b> – otvorenost i podrška za druge.'
        ]

    },
    aboutGv:{
        tabName:"Građansko vaspitanje",
        title:"O programu/predmetu GRAĐANSKO VASPITANJE",
        txt1:"<b>ISTORIJAT:</b> Građansko vaspitanje (GV) ili vaspitanje za demokratiju i građansko društvo, uvedeno je u sistem obrazovanja Republike Srbije kao fakultativni predmet školske 2001/2002. godine za prvi razred osnovne i prvi razred srednje škole. Nakon toga, tokom školske 2005/2006. godine građansko vaspitanje je uključeno u u sve razrede osnovne i srednje škole, kao obavezan izborni predmet, a učenicima je data mogućnost da biraju pohađanje građanskog vaspitanja ili verske nastave.        <br><br><b>Cilj uvođenja predmeta</b> bio je da učenici steknu znanja, razviju sposobnosti i veštine i usvoje vrednosti koje su pretpostavka za celovit razvoj ličnosti i za kompetentan, odgovoran i angažovan život u savremenom građanskom društvu.<br><br><b>AKTUELNO O GV:</b> U ovom momentu sprovodi se reforma i ovog programa. Novi, osavremenjeni i unapređeni programi Građanskog vaspitanja realizuju u svim razredima osnovne škole i gimnazije. U srednjim stručnim školama novi programi biće uvedeni i prilagođeni za trogodišnje i četvorogodišnje obrazovanje.<br>",
    
        li1:{
            title:'<br><b>GV U OSNOVNOJ ŠKOLI:</b> Novi programi u osnovnim školama koncipirani su tako da se kroz svaki razred obrađuju sadržaji iz četiri teme: ljudska prava, demokratsko društvo, procesi u savremenom svetu i građanski aktivizam.<br>Sadržaj programa za osnovnu školu je bogat. Učenici koji se opredele za Građansko vaspitanje biće u prilici da se bave pitanjima identiteta, potreba, prava, kršenja i zaštite prava, osećanja, funkcionisanja zajednice, komunikacije, saradnje, odgovornosti, sukoba, vrednosti, bezbednosti, različitosti, ravnopravnosti, diskriminacije, stereotipa, predrasuda, osetljivih grupa, lokalne zajednice, solidarnosti, volontiranja, prava na zdravu životnu sredinu, kulture, tradicije, migracije, potrošačkog društva, rodne ravnopravnosti, medija.<br><br><b>GV U GIMNAZIJI:</b> Novi programi u gimnaziji realizuju se kroz istraživačko-projektne aktivnosti učenika/-ca u okviru osam tema (po dve u svakom razredu). Teme su:',
            itms:[
                'Bezbednost mladih (I razred)',
                'Globalizacija (I razred)',
                'Svi različiti, a svi ravnopravni (II razred)',
                'Mediji za građane, građani za medije (II razred)',
                'Ljudska prava, građani i demokratija (III razred)',
                'Mir i pretnje miru (III razred)',
                'Ekonomska i socijalna prava (IV razred)',
                'Pravo na zdravu životnu sredinu (IV razred)'
            ]
        },

        li2:{
            title:'<br><br><b>GV U SREDNJOJ STRUČNOJ ŠKOLI:<b> Srednje stručne škole i dalje rade po starim programima GV. Teme koje se realizuju po razredima su: ',
            itms:[
                'I razred: Ja mi i drugi/Komunikacija u grupi/ Odnosi u grupi,zajednici/',
                'II razred: Osnovni pojmovi/Prava i odgovornosti/Vrste prava i odnosi među pravima/Planiranje i izvođenje akcije u korist prava',
                'III razred: Demokratija i politika/Građanin i građansko društvo/Građanska i politička prava i pravo na građansku inicijativu/Planiranje konkretne akcije',
                'IV razred: Svet informacija/Svet profesionalnog razvoja'

            ]
        }
    },

    activity:{
        tabName:"Aktivnosti",
        title:"Aktivnosti"
    },

    projects:{
        
        tabName:"Projekti",
        title:"Projekti"
    },
    center:{
        tabName:"Centri",
        title:"Centri"
    },

    resources:{
        tabName:"Resursi",
        title:"Resursi"
    },

    contact:{
        tabName:"Kontakt",
        title:"Kontakt"
    }
}
export default pages;